<template>
  <div>
    <!-- 标题 -->
    <!-- tab栏 -->
    <van-sticky>
      <van-tabs
        @click="onClickOnlyOne"
        color="#f63e3b"
        title-active-color="#f63e3b"
      >
        <van-tab name="全部" title="全部"> </van-tab>
        <van-tab
          v-for="item in CateList"
          :key="item.TKId"
          :name="item.TKId"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs>
    </van-sticky>
    <div class="information">
      <div
        class="center"
        style="padding: 17px 6px"
        v-for="item in commNoticeList"
        :key="item.index"
      >
        <div class="nian">{{ item.IssTime.split(" ")[0].split("-")[0] }}年</div>
        <div
          style="
            position: relative;
            top: -8px;
            width: 50px;
            height: 5px;
            background: linear-gradient(to right, #d44f43, #fff);
          "
        ></div>

        <div>
          <div style="display: flex; margin-bottom: 10px; margin-top: 14px">
            <span class="yue">{{
              item.IssTime.split(" ")[0].split("-")[1] +
              "/" +
              item.IssTime.split(" ")[0].split("-")[2]
            }}</span>
            <span class="yuan"></span>
            <div class="title">{{ item.Title }}</div>
          </div>

          <div
            style="display: flex"
            @click="gotoUrl('/huayang/suggest/' + item.RIId)"
          >
            <div class="line"></div>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: baseline;
              "
            >
              <div class="content">
                {{ item.Summary }}
              </div>
              <div class="img" ref="getHeight">
                <img
                  v-if="item.Thematic != null && item.Thematic != ''"
                  :src="item.Thematic"
                  alt=""
                  style="min-height: 80px"
                />
                <img
                  v-else
                  src="http://alycommunity.anluoyun.cn/sx-jgz/2023/6/d230d06c-3c3.png"
                  alt=""
                  style="min-height: 80px"
                />
              </div>
              <div class="recman">发布人：{{ item.IssMan }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="center" v-for="item in commNoticeList" :key="item.index">
        <div @click="gotoUrl('/huayang/suggest/' + item.RIId)">
          <div class="Content">
            <div
              style="display: inline-block; width: 33%"
              class="ContentA"
              ref="getHeight"
            >
              <img
                v-if="item.Thematic != null && item.Thematic != ''"
                style="border-radius: 5px; min-height: 90px"
                :src="item.Thematic"
                alt=""
              />
              <img
                v-else
                src="http://alycommunity.anluoyun.cn/sx-jgz/2023/6/d230d06c-3c3.png"
                alt=""
                style="border-radius: 5px; min-height: 90px"
              />
            </div>
            <div style="display: inline-block; width: 63%" class="ContentB">
              <div class="centerTitle van-multi-ellipsis--l2">
                {{ item.Title }}
              </div>
              <span
                class="centertime van-multi-ellipsis--l2"
                style="margin: 5px 0px; font-size: 13px; color: #666666"
                >{{ item.Summary }}</span
              >
            </div>
            <span
              class="centertime"
              style="float: right; margin-top: -10px; color: black"
              >{{ item.IssTime | timeFilter }}</span
            >
          </div>
        </div>
      </div> -->
      <van-empty description="暂无信息" v-if="!commNoticeList.length" />
    </div>
  </div>
</template>
<script>
import config from "@/config/index";
import {
  WeGetInfoKindInfoPage,
  WeGetRealInfoPage,
  WeGetMyActivityPage,
} from "@/api/RealInfo";
import { WeGetAppletInfo } from "@/api/index";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data() {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      partyNoticeList: [],
      commNoticeList: [],
      CateList: [],
      area: "",
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        // let gstime = value.replace('T', ' ')
        let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  created() {
    this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    // 活动五星党建亮点
    // this.getNoticeList('书记茶话会');
    // this.getNoticeList('多元化社区');
    // this.getNoticeList('社群e家联防巡逻');
    // this.getNoticeList('爱心大妈');
    // this.getNoticeList('餐饮专题培训');
    // 获取活动通知
    this.getCommNoticeList();
    this.getInfoKindInfoPage();
  },
  methods: {
    // 获取资讯分类
    async getInfoKindInfoPage() {
      const res = await WeGetInfoKindInfoPage({ paKind: 3 });
      console.log(res, "res");
      this.CateList = res.data.data;
      console.log(this.CateList, "this.CateList");
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      // this.list = [];
      // this.listfrom.TKId = name;
      // this.listfrom.page = 1;
      this.getCommNoticeList(name);
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 获取活动通知
    getCommNoticeList(val) {
      WeGetRealInfoPage({ page: 1, limit: 10, paKind: 3, tkId: val }).then(
        (res) => {
          if (res.data.code == 0) {
            this.commNoticeList = res.data.data;
          }
        }
      );
    },
    // 通知消息 五星支部创建
    getNoticeList(row) {
      WeGetMyActivityPage({
        taskClassification: row,
        page: 1,
        limit: 1,
        openid: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          this.partyNoticeList.push(res.data.data[0]);
          for (var i = 0; i < this.NoticeList.length; i++) {
            var item = this.NoticeList[i].IssTime;
            item = this.dataFormat(item);
            this.partyNoticeList[i].IssTime = item;
          }
        }
      });
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        console.log(res, "res");
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1;
          imgs[1] = list.data.SysPic2;
          imgs[2] = list.data.SysPic3;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}

.nian {
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
}

.yuan {
  width: 7px;
  height: 7px;
  background: rgb(252, 72, 57);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin: 7px 0;
}
.yue {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #231815;
  margin-right: 8px;
}

.line {
  width: 1px;
  height: 198px;
  background: #e8e8e8;
  margin-left: 52px;
  margin-right: 24px;
}

.title {
  margin-left: 21px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  height: 63px;
  font-size: 14px;
  font-family: PingFang SC;
  color: #231815;
  font-weight: normal;
  margin-top: 6px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.img img {
  border-radius: 11px;
  height: 74px;
  width: 74px;
  margin-top: 12px;
}

.recman {
  margin-top: 10px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

/deep/ .van-tab {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  /* color: #000000; */
  /* font-family: PingFangSC; */
}

/deep/ .van-tab--active {
  font-weight: bold;
  font-size: 15px;
  color: #187fc8;
}
</style>